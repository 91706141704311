import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ActivityView from '../views/ActivityView.vue'
import LoginView from '../views/LoginView.vue'
import ChallengeView from '../views/ChallengeView.vue'
import ProfileView from '../views/ProfileView.vue'
import LeaderBoardView from '../views/LeaderBoardView.vue'
import TeamScoreView from '../views/TeamScoreView.vue'
import FinalResultView from '../views/FinalResultView.vue'
import FinalScoreView from '../views/FinalScoreView.vue'
import FailedView from '../views/FailedView.vue'
import store from '../store/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/activity',
    name: 'activity',
    component: ActivityView
  },
  {
    path: '/challenge',
    name: 'challenge',
    component: ChallengeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/team_score_board',
    name: 'team_score_board',
    component: TeamScoreView
  },
  {
    path: '/leader_board',
    name: 'leader_board',
    component: LeaderBoardView
  },
  {
    path: '/leader_board',
    name: 'leader_board',
    component: LeaderBoardView
  },
  {
    path: '/final_score',
    name: 'final_score',
    component: FinalScoreView
  },
  {
    path: '/final_result',
    name: 'final_result',
    component: FinalResultView
  },
  {
    path: '/register-failed',
    name: 'register-failed',
    component: FailedView
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/landing',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // @ts-ignore
  if(!['login', 'register-failed'].includes(to.name) && !store.state.credentials.access_token)
    next({ name: 'login' })
  next()
})

export default router
